import {createRouter, createWebHistory} from 'vue-router'
import Main from "../pages/Main";

const routes = [{
    path: '/',
    name: 'Main',
    component: Main
},
    {
        path: '/admin',
        name: 'Admin',
        component: () => import('../pages/Admin')
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes
})

export default router
