<template>
  <video class="video-bg" autoplay muted loop id="video-bg">
    <source
      src="https://simplevideos.fra1.digitaloceanspaces.com/Comp_1.mp4"
      type="video/mp4"
    />
  </video>
  <div class="content">
    <div class="container">
      <div class="logo"><img src="../assets/images/logo.svg" alt="" /></div>
      <div class="dishes-box">
        <div class="title-menu font-heading-pro">LÕUNAMENÜÜ</div>
        <div class="title-menu-small font-heading-pro">Обеденное меню</div>

        <div class="day-title font-heading-pro">
          <div class="day-name">{{ todayEt }} / {{ today }}</div>
          <div class="day-num">{{ todayNumber }}</div>
        </div>

        <div class="menu">
          <div
            v-for="dish in dishes"
            :key="dish.id"
            class="dishes-item flex justify-space-between"
          >
            <div class="info">
              <div class="title">{{ dish.title }}</div>
              <div class="small-title">{{ dish.title_ru }}</div>
            </div>
            <div class="price">{{ dish.price }}€</div>
          </div>
        </div>

        <div class="garnish-box" v-show="garnishes.length">
          <div class="garnish-title font-heading-pro">Garniirid / Гарниры:</div>
          <div class="garnish-item">
            <div v-for="garnish in garnishes" :key="garnish.id">
              {{ garnish.title }} / {{ garnish.title_ru }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Main",
  data: () => ({
    url: "//api.simpleresto.ee",
    // url: 'http://localhost:1337',
    dishes: [],
    garnishes: [],
    categoryDays: [],
    activeBar: false,
    valueDays: [],
    actionStatus: true,

    dayCurrent: [
      "Воскресенье",
      "Понедельник",
      "Вторник",
      "Среда",
      "Четверг",
      "Пятница",
      "Суббота",
    ],
    dayCurrentEt: [
      "Pühapäev",
      "Esmaspäev",
      "Teisipäev",
      "Kolmapäev",
      "Neljapäev",
      "Reede",
      "Laupäev",
    ],
    today: "",
    todayEt: "",
    todayNumber: 0,
  }),

  mounted() {
    this.getDishes();
    this.getGarnishes();
    let dayTitle = String(new Date().getDay());
    this.today = this.today.replace(this.today, this.dayCurrent[dayTitle]);
    this.todayEt = this.todayEt.replace(
      this.todayEt,
      this.dayCurrentEt[dayTitle]
    );
    let getMonth = new Date().getMonth();
    this.todayNumber = `${new Date().getDate()}.${getMonth < 9 ? 0 : ""}${
      getMonth + 1
    }`;
  },

  methods: {
    getDishes() {
      this.axios
        .get(`${this.url}/dishes`)
        .then((res) => {
          this.dishes = res.data;

          this.dishes = this.dishes.filter((item) => {
            item.days = item.days.filter((d) => d.title === this.today);
            return item.days.length;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getGarnishes() {
      this.axios
        .get(`${this.url}/garnishes`)
        .then((res) => {
          this.garnishes = res.data;
          this.garnishes = this.garnishes.filter((item) => {
            item.days = item.days.filter((d) => d.title === this.today);
            return item.days.length;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getDaysRu() {},
  },
};
</script>


<style lang='scss' scoped>
$dark: #19191b;

.logo {
  display: none;
  margin: 30px auto;
  width: 150px;
}

/*------------------------Dishes------------------------*/

.font-heading-pro {
  font-family: "Heading Pro";
}

#video-bg {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.image-bg {
  display: none;
}

.content {
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 2;
  margin-top: 394px;
}

.title-menu {
  font-size: 144px;
  line-height: 156px;
  text-align: center;
  letter-spacing: 0.15em;
  font-weight: 600;
}

.title-menu-small {
  text-align: center;
  font-size: 72px;
  line-height: 81px;
  margin-top: 8px;
  letter-spacing: 0.15em;
}

.day-title {
  display: flex;
  justify-content: space-between;
  margin-top: 96px;
  font-size: 72px;
}

.menu {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  margin: 24px 0 48px;
  padding: 24px 0;
}

.dishes-box {
  padding: 15px 0;

  .dishes-item {
    position: relative;
    margin: 60px 0;

    .info {
      position: relative;
      background: inherit;
      padding-right: 15px;
      z-index: 1;
    }

    .title,
    .price {
      font-size: 72px;
      font-weight: 600;
    }

    .small-title {
      line-height: 53px;
      font-size: 48px;
    }
  }
}

/*------------------------Garnish------------------------*/

.garnish-box {
  text-align: center;

  .garnish-title {
    font-size: 72px;
    line-height: 81px;
  }
  .garnish-item {
    margin: 15px 0;
    font-size: 48px;
  }
}
@media (min-width: 2140px) {
  #video-bg {
    width: 2160px;
  }
  .content {
    margin-top: calc(394px * 2);
  }
}

@media (max-width: 812px) {
  .logo {
    display: block;
  }

  .content {
    margin-top: 0;
    padding-top: 30px;
    background: linear-gradient(to right, #232628, #16181b);
    min-height: 100vh;
  }
  .title-menu {
    font-size: 54px;
    line-height: 54px;
  }

  .title-menu-small {
    font-size: 30px;
    margin-top: 0;
  }

  .day-title {
    font-size: 30px;
    margin-top: 38px;
  }

  .dishes-box .dishes-item {
    margin: 30px 0;
  }

  .dishes-box .dishes-item .title,
  .dishes-box .dishes-item .price {
    font-size: 26px;
  }

  .dishes-box .dishes-item .small-title {
    line-height: 24px;
    font-size: 20px;
  }

  .garnish-box .garnish-title {
    font-size: 28px;
    line-height: 35px;
  }

  .garnish-box .garnish-item {
    font-size: 20px;
  }
  .video-bg {
    display: none;
  }
}
</style>
